/** @jsx jsx */
import { jsx, Button, Text } from "theme-ui";
import { Fragment, useEffect } from "react";
import { graphql } from "gatsby";
import { parseISO, isAfter, startOfYesterday } from "date-fns";

import {
  BackLink,
  Card,
  Heading,
  Layout,
  PortableText,
  ProtectedImage,
} from "components";
import { getArtistName, getArtworkPath } from "utils";

function ExhibitionPageInner({
  backLinkTo,
  name,
  date,
  gatsbyImageData,
  featured_artwork,
  description,
  including_works_by,
  installationShots,
  openInstallationShots,
  openContactForm,
  filteredArtworks,
  handleArtworkClick,
  selectedInstallationShotId,
}) {
  useEffect(() => {
    if (selectedInstallationShotId) {
      openInstallationShots({ installtionShotId: selectedInstallationShotId });
    }
  }, [selectedInstallationShotId]);

  return (
    <Fragment>
      <BackLink to={backLinkTo} sx={{ mb: "s" }}>
        exhibitions
      </BackLink>
      <Heading mb="s" sx={{ fontSize: "24px" }}>
        {name}
      </Heading>
      <Text as="p" sx={{ color: "primary", mb: "m" }}>
        {date}
      </Text>

      <div
        sx={{
          mb: "l",
          display: "flex",
          flexDirection: ["column", "row-reverse"],
          width: "100%",
        }}
      >
        {gatsbyImageData && (
          <div
            sx={{
              ml: [null, "l"],
              mb: ["l"],
              width: [null, "400px", "600px"],
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <ProtectedImage
              loading="eager"
              image={gatsbyImageData}
              alt={name}
              sx={{ mb: "xxs" }}
            />

            {featured_artwork && (
              <Text as="p">
                {[
                  getArtistName(featured_artwork.artist),
                  featured_artwork?.work_title || featured_artwork?.name,
                  featured_artwork?.work_date,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </Text>
            )}
          </div>
        )}

        <div sx={{ flexGrow: 1, flexShrink: 1 }}>
          {description && (
            <PortableText content={description} sx={{ mb: "m" }} />
          )}

          {including_works_by && (
            <div>
              <Text as="p" mb="0">
                Including works by:
              </Text>
              <PortableText content={including_works_by} sx={{ mb: "m" }} />
            </div>
          )}

          {installationShots.length > 0 && (
            <Button
              variant="asLink"
              type="button"
              onClick={openInstallationShots}
            >
              View installation shots
            </Button>
          )}

          {openContactForm && (
            <div
              sx={{
                mt: "m",
                pt: "m",
                borderTop: "solid 1px #ddd",
              }}
            >
              <Button variant="asLink" type="button" onClick={openContactForm}>
                Enquire
              </Button>
            </div>
          )}
        </div>
      </div>

      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mx: "-s",
          clear: "both",
        }}
      >
        {filteredArtworks.map((artwork) => (
          <div key={artwork._id} sx={{ m: "m", mt: "s" }}>
            <Card
              to={getArtworkPath(artwork)}
              gatsbyImageData={artwork?.work_image?.asset?.gatsbyImageData}
              imageAlt={artwork.name}
              onClick={handleArtworkClick(artwork)}
              title={getArtistName(artwork.artist)}
              subTitle={[artwork.name, artwork.work_date]
                .filter(Boolean)
                .join(", ")}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
}

function ExhibitionPage({ data: { exhibition }, location: { hash } }) {
  const {
    _id,
    end_date,
    start_date,
    name,
    year,
    description,
    image,
    featured_artwork,
    including_works_by,
    artworks,
    installationShots,
  } = exhibition;

  const yesterday = startOfYesterday();
  const isAfterYesterday =
    (end_date && isAfter(parseISO(end_date), yesterday)) ||
    isAfter(parseISO(start_date), yesterday);

  const filteredArtworks = artworks.filter((a) => {
    return a?.artist?.first_name || a?.artist?.last_name;
  });

  const gatsbyImageData =
    image?.asset?.gatsbyImageData ||
    featured_artwork?.work_image?.asset?.gatsbyImageData;

  return (
    <Layout pageTitle={[name, year, "Exhibitions"]} artworks={artworks}>
      {({ openArtworkModal, openContactForm, openInstallationShots }) => {
        const handleArtworkClick = (artwork) => (evt) => {
          evt.preventDefault();
          openArtworkModal({ artworkId: artwork._id });
        };

        return (
          <ExhibitionPageInner
            backLinkTo={isAfterYesterday ? "/exhibitions" : "/exhibitions?past"}
            name={name}
            date={[start_date, end_date].filter(Boolean).join(" - ")}
            gatsbyImageData={gatsbyImageData}
            featured_artwork={featured_artwork}
            description={description}
            including_works_by={including_works_by}
            installationShots={installationShots}
            openInstallationShots={({ installtionShotId }) =>
              openInstallationShots({ installationShots, installtionShotId })
            }
            filteredArtworks={filteredArtworks}
            handleArtworkClick={handleArtworkClick}
            openContactForm={() => openContactForm({ exhibition })}
            selectedInstallationShotId={
              hash ? hash.replace(/#/, "") : undefined
            }
          />
        );
      }}
    </Layout>
  );
}

export const query = graphql`
  query Exhibition($id: String) {
    exhibition: sanityExhibition(_id: { eq: $id }) {
      _id
      end_date(formatString: "Do MMMM YYYY")
      start_date(formatString: "Do MMMM YYYY")
      name
      year: start_date(formatString: "yyyy")
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      including_works_by: _rawIncludingWorksByContent(
        resolveReferences: { maxDepth: 10 }
      )

      image {
        asset {
          gatsbyImageData(height: 686)
        }
      }

      featured_artwork {
        _id
        name
        work_image {
          asset {
            gatsbyImageData(height: 686)
          }
        }
        work_title
        artist {
          _id
          first_name
          last_name
          yearOfDeath: date_of_death(formatString: "yyyy")
          yearOfBirth: date_of_birth(formatString: "yyyy")
        }
        work_date
      }

      artworks {
        _id
        name
        medium {
          medium
        }
        work_image {
          asset {
            gatsbyImageData(height: 220)
          }
        }
        image: work_image {
          asset {
            gatsbyImageData(height: 800)
          }
        }
        image__short: work_image {
          asset {
            gatsbyImageData(height: 500)
          }
        }
        work_title
        artist {
          _id
          first_name
          last_name
          yearOfDeath: date_of_death(formatString: "yyyy")
          yearOfBirth: date_of_birth(formatString: "yyyy")
        }
        work_date
        content: _rawDetails(resolveReferences: { maxDepth: 10 })
      }
      installationShots {
        _id
        id
        name
        image {
          asset {
            gatsbyImageData(height: 800)
          }
        }
        description: _rawDescription(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

export default ExhibitionPage;
